<template>
  <div class="pb-3xl">
    <Breadcrumbs
      v-if="breadcrumbItems.length > 0"
      :items="fullBreadcrumbItems"
      class="mx-xs md:mx-md my-2xs md:my-xs"
      data-fs="productBreadcrumbs"
    />
    <Stack
      v-if="product"
      justify="center"
      align="start"
      class="product-page bg-grey-lighter"
      direction="col"
      :breakpoints="{ direction: { md: 'row' }, justify: { md: 'between' } }"
    >
      <Stack class="pb-none w-full" gap="sm" direction="col">
        <ImageGallery v-if="product && product[0]?.images" :image="product[0].images" data-fs="pdpImage" />
        <ProductDetails
          v-if="isMobile() && product.length > 0"
          :product="product"
          :price="priceRef"
          :product-details="productDetails"
        />
        <Stack direction="col" class="product-description w-full px-xs sm:px-none">
          <Heading size="md">Product Overview</Heading>
          <div v-if="product && product[0]?.description" class="rich-text" v-html="product[0].description" />
          <Heading v-if="files.length > 0" size="xs" weight="semi">Downloads</Heading>
          <Stack direction="col">
            <div v-for="(file, index) in files" :key="index">
              <a :href="file.value" target="_blank">
                <Stack align="center" class="cursor-pointer">
                  <Icon name="download" :size="16" color="#1e55c0" />
                  <Text class="text-mkm-blue-light">{{ getCleanFileName(file.name) }}</Text>
                </Stack>
              </a>
            </div>
          </Stack>
          <Specifications />
        </Stack>
      </Stack>
      <ProductDetails
        v-if="!isMobile() && product.length > 0"
        :product="product"
        :price="priceRef"
        :product-details="productDetails"
      />
    </Stack>
    <LazyRelatedProducts
      v-if="relatedProducts.items.length > 0"
      :products="relatedProducts"
      :breadcrumbs="breadcrumbItems"
      :prices="relatedProductsPrices"
      title="You may also be looking for"
      :cta="{ text: 'Shop all', link: breadcrumbItems[breadcrumbItems.length - 1]?.href }"
    />
    <LazySpecificationsTray
      v-if="product && product[0] && product[0].custom_fields"
      :specification-fields="product[0].custom_fields ?? []"
    />
    <LazySustainabilityTray
      v-if="product && product[0] && product[0].custom_fields"
      :product-sustainability-data="product[0].custom_fields"
    />
    <LazyTallyTray
      v-if="productDetails && product"
      :product-info="product[0]"
      :product-default-pricing="productDetails"
      :price="priceRef"
      :selected-branch-id="selectedBranchId"
    />
    <LazyDropShipTray />
  </div>
</template>
<script lang="ts" setup>
import useJsonld from "~/composables/useJsonId";
import { BreadcrumbItemProps } from "@/components/UI/Breadcrumbs/breadcrumbTypes";
import { useGA4EcommerceEvents } from "mkm-gtm";

const { selectedBranch, selectedBranchId } = useBranches();
const { CACHE_ENABLED } = useRuntimeConfig().public;
const { user } = useUser();
const route = useRoute();
const { pushGA4EcommerceEvent, GA4EcommerceEvents } = useGA4EcommerceEvents();
useHead({
  bodyAttrs: {
    class: "bg-neutral-lighter",
  },
});
const { fetchProductDetails, fetchRelatedProducts } = useSearchAndMerch();
const { getPrice, fetchPrice, prices: productPrice } = usePrices();
const productDetails = ref<any>({});
const relatedProducts = ref<any>({});
const relatedProductsPrices = ref<any>();
const categoryTree = useCategoryTree();
const priceRef = ref();
interface CustomFields {
  name: string;
  value: string;
}

const files = ref<CustomFields[]>([]);

const pageTitle = ref();
pageTitle.value = "";
interface PageMetaItem {
  title: string;
  description: string;
}
const pageMeta = ref<PageMetaItem[]>([]);

//Get the product code from the URL
const getProductId = computed(() => {
  const inputString = route.params.productHandle.toString();
  let lastIndex = inputString.lastIndexOf("-");
  return inputString.slice(lastIndex + 1).toUpperCase();
});
const { fetchProduct, getCacheProduct, data: product } = useProduct(getProductId.value);

CACHE_ENABLED ? await getCacheProduct() : await fetchProduct();

if (product && product.value && product.value.length > 0) {
  pageTitle.value = product.value[0].name;
  pageMeta.value = [{ title: product.value[0].name, description: product.value[0].meta_description }];
  files.value =
    product.value[0].custom_fields && product.value[0].custom_fields.length > 0
      ? product.value[0].custom_fields.filter((field) => field.value.includes("pdf"))
      : [];
}

await fetchProductDetails(getProductId.value).then((data) => {
  productDetails.value = data;
});

if (Object.keys(productDetails.value).length > 0) {
  await fetchRelatedProducts(getProductId.value).then(async (data) => {
    relatedProducts.value = data;
    if (user.value && relatedProducts.value) {
      const collectProductIds = relatedProducts.value?.items.map((i: any) => i.itemId.id);
      relatedProductsPrices.value = await getPrice(collectProductIds.join(","));
    } else {
      relatedProductsPrices.value = [];
    }
  });
}

const pageJson = ref();
pageJson.value = {
  "@context": "https://schema.org",
  "@type": "Product",
  name: pageTitle,
  image: "Product Thumbnail",
  offers: {
    "@type": "Offer",
    priceCurrency: "GBP",
    price: priceRef.value,
    // ...(!!isAddToCardButtonAvailable() && { "availability": "https://schema.org/InStock" }),
    // ...(!!isQuoteButtonAvailable() && { "availability": "https://schema.org/OutOfStock" })
  },
  description: "Product Description".replaceAll("<p>", "").replaceAll("</p>", ""),
  // ...productSchemaProperty.value,
};
const { inject, script: jsonScript } = useJsonld();
inject(pageJson.value);

useHead({
  title: pageTitle,
  meta: pageMeta,
  script: jsonScript,
});

const isMobile = (): boolean => {
  if (typeof window !== "undefined") {
    return window.innerWidth <= 760;
  }
  return false;
};

interface Category {
  id: number;
  parent_id: number;
  name: string;
  is_visible: boolean;
  depth: number;
  path: number[];
  children: Category[];
  url: string;
}

const findCategoryById = (data: Category[], id: number): Category | undefined => {
  const iter = (categories: Category[]): Category | undefined => {
    for (const category of categories) {
      if (category.id === id) {
        return category;
      }
      if (Array.isArray(category.children)) {
        const result = iter(category.children);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };
  return iter(data);
};

const categories = ref<Category[]>([]);

function addCategoryIfVisible(list: Category[], id: number) {
  const category = findCategoryById(list, id);
  if (category && category.is_visible) {
    categories.value.push(category);
  }
}

function addParentCategories(list: Category[], categoryIndex: number) {
  const category = categories.value[categoryIndex];
  if (category?.parent_id) {
    addCategoryIfVisible(list, category.parent_id);
  }
}

function getProductCategories() {
  categories.value = [];

  if (product.value && product.value[0]?.categories?.length > 0) {
    const productCategories = product.value[0].categories;

    if (categoryTree?.data?.value) {
      const list = categoryTree.data.value as Category[];

      addCategoryIfVisible(list, productCategories[0]);

      for (let i = 0; i < 3; i++) {
        addParentCategories(list, i);
      }

      categories.value.reverse();
    }
  }
}

if (Object.keys(productDetails.value).length > 0) {
  getProductCategories();
}

const breadcrumbItems: BreadcrumbItemProps[] = categories.value.map((category: Category) => ({
  title: category.name,
  href: category.url,
  disabled: !category.is_visible,
}));

const fullBreadcrumbItems = computed(() => {
  return [...breadcrumbItems, { title: pageTitle.value, href: "/product" }];
});

const getCleanFileName = (fileName: string) => {
  return fileName.replace(/^file_/i, "");
};

const findProductData = (name: string) => {
  return productDetails.value.customAttrs.find((item: any) => item.name === name);
};

function extractAttributeValue(objects: {}, attributeName: string): any {
  if (Array.isArray(objects)) {
    const objectWithAttribute = objects.find((obj) => obj.name === attributeName);
    return objectWithAttribute ? objectWithAttribute.values[0] : null;
  }
  return null;
}
const getStockType = (availabilityFlag: number) => {
  return availabilityFlag === 1 ? "BRANCH STOCKED" : availabilityFlag === 4 ? "DROPSHIP" : null;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
onMounted(async () => {
  if (product?.value) {
    const url = product?.value?.[0]?.custom_url.url;
    if (!route.path.includes(url)) {
      return navigateTo(url, { redirectCode: 301 });
    }
  }
  if (user.value && getProductId.value) {
    await fetchPrice(getProductId.value);

    priceRef.value = productPrice?.value?.[0] ?? null;
  }

  setTimeout(async () => {
    const userCategory = user.value?.account.business_type.split("|");
    const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;

    await pushGA4EcommerceEvent(GA4EcommerceEvents.VIEW_ITEM, {
      logged_in: user.value !== null || false,
      user_id: user.value?.id ?? null,
      customer_category_1: user ? isTrade : "Guest",
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_type: user.value?.account.type ?? null,
      category: categories.value[0]?.name ?? null,
      account_owning_branch: user.value?.account.branch_id ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
      stock_type: getStockType(Number(extractAttributeValue(productDetails.value.customAttrs, "availability_flag"))),
      page_type: "product",
      ecommerce: {
        currency: "GBP",
        value: Number.parseFloat(findProductData("price_inc_vat")?.["values"][0]) ?? null,
        items: [
          {
            item_id: productDetails.value?.itemId?.id,
            bigcommerce_item_id: product?.value?.[0]?.id.toString() ?? null,
            item_name: product?.value?.[0]?.name,
            price: Number.parseFloat(findProductData("price_inc_vat")?.["values"][0]) ?? null,
            price_ex_vat: Number.parseFloat(findProductData("price")?.["values"][0]) ?? null,
            quantity: 1,
          },
        ],
      },
    });
  }, 2000);
});

//Watchers
watch(
  selectedBranch,
  async (branch) => {
    if (branch && process.client) {
      await Promise.all([
        fetchProduct(),
        fetchProductDetails(getProductId.value).then((data) => {
          productDetails.value = data;
        }),
        await fetchRelatedProducts(getProductId.value).then(async (data) => {
          relatedProducts.value = data;
          if (user.value && relatedProducts.value) {
            const collectProductIds = relatedProducts.value?.items.map((i: any) => i.itemId.id);
            relatedProductsPrices.value = await getPrice(collectProductIds.join(","));
          } else {
            relatedProductsPrices.value = [];
          }
        }),
      ]);
    }
  },
  { immediate: true },
);

watch(
  () => [user, relatedProducts],
  async () => {
    if (user.value && relatedProducts.value) {
      const collectProductIds = relatedProducts.value?.items.map((i: any) => i.itemId.id);
      relatedProductsPrices.value = await getPrice(collectProductIds.join(","));
    } else {
      relatedProductsPrices.value = [];
    }
  },
  { deep: true },
);
watch(user, async () => {
  if (user.value && getProductId.value) {
    await fetchPrice(getProductId.value);

    priceRef.value = productPrice?.value?.[0] ?? null;
  }
});
</script>
<style>
.product-page {
  max-width: 1520px;
  margin: 0 auto;
  width: 100%;
}

@media (min-width: 768px) {
  .product-page {
    padding: 0 16px;
  }
}
.green-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 6px;
}
.nowrap {
  white-space: nowrap;
}
</style>
