<template>
  <Stack direction="col" gap="xs">
    <GreenerOption
      :variant="processedBadgeName"
      class="mb-2xs"
      v-if="badgeFields.length > 0"
      data-fs="greenOptionBadge"
    />
    <div class="images-container">
      <div v-for="(iconField, index) in iconFields" :key="index" class="image">
        <NuxtImg
          :src="`/sustainability/${iconField.name}.svg`"
          :alt="iconField.description"
          @click="setSustainabilityTray"
        />
      </div>
    </div>
    <nuxt-link v-if="badgeFields.length > 0" to="/sustainability/greener-options" class="sustainable-option-info"
      ><Text weight="bold" class="ui-text-mkm-blue-light" data-test-id="findOutMore"
        >More about MKM’s Greener Option</Text
      ></nuxt-link
    >
  </Stack>
</template>

<script setup lang="ts">
import { availableBadges } from "../../../helpers/sustainability/sustainableDescriptions.js";
const { setSustainabilityTray } = useUIState();
interface CustomField {
  name: string;
  value: string;
  description: string;
}
const data = defineProps<{ customFields: CustomField[] }>();

const removeIconPrefix = (name: string) => {
  return name.slice("icon-".length);
};

const iconFields = computed(() => {
  return data.customFields.filter(
    ({ name, value }: CustomField) => availableBadges.includes(name) && value.includes("Y"),
  );
});

const badgeFields = computed(() => {
  return data.customFields.filter(
    (field: CustomField) =>
      field.name === "icon-green" ||
      field.name === "icon-materials" ||
      field.name === "icon-energy" ||
      field.name === "icon-planet",
  );
});

const processedBadgeName = computed((): "green" | "energy" | "materials" | "planet" | "none" => {
  if (badgeFields.value.length === 1) {
    return removeIconPrefix(badgeFields.value[0].name) as "green" | "energy" | "materials" | "planet" | "none";
  }
  return "green";
});
</script>

<style lang="scss">
.images-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;

  .image {
    margin: 0 16px 0 0;
    cursor: pointer;

    img {
      width: 60px;
    }
  }
}
</style>
