import { Ref, ref } from "vue";
import { JsonLD } from "./types";

function useJsonld() {
  const script: Ref<(string | { type: string; innerHTML: string })[]> = ref([]);

  function inject(json: JsonLD) {
    if (!json) {
      return;
    }

    const jsonScript = {
      type: "application/ld+json",
      innerHTML: JSON.stringify(json, null, ""),
    };

    script.value = [jsonScript];
  }

  return { script, inject };
}

export default useJsonld;
