// import { toRefs } from '@vueuse/shared';
import type { UseProductState, FetchProduct } from "./types";

export const useProduct = (sku: string) => {
  const state = useState<UseProductState>(`useProduct-${sku}`, () => ({
    data: null,
    loading: false,
  }));

  const fetchProduct: FetchProduct = async () => {
    state.value.loading = true;
    const { data, error } = await useTimedAsyncData("productBC", () => useSdk().bcAPI.getProductBySku({ skus: [sku] }));
    if (error.value || !data.value || (Array.isArray(data.value) && data.value.length === 0)) {
      throw createError({
        statusCode: 404,
        message: "Product not found",
        statusMessage: "No product data found",
        fatal: true,
      });
    }
    state.value.data = data.value;
    state.value.loading = false;
    return data;
  };

  const getCacheProduct = async () => {
    state.value.loading = true;
    const { data: cacheData, error: cacheError } = await useTimedAsyncData("productCacheBC", () =>
      useSdk().redis.getOrSetCache({
        key: `cache:product-${sku}`,
        data: async () => useSdk().bcAPI.getProductBySku({ skus: [sku] }),
        timeToLive: 60 * 60 * 12,
      }),
    );
    if (cacheError.value || !cacheData.value || (Array.isArray(cacheData.value) && cacheData.value.length === 0)) {
      await fetchProduct();
      return;
    }

    state.value.data = cacheData.value;
    state.value.loading = false;
    return cacheData;
  };

  return {
    fetchProduct,
    getCacheProduct,
    ...toRefs(state.value),
  };
};

export default useProduct;
