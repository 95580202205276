export const SUSTAINABLE_DESCRIPTIONS = [
  {
    name: "rec100",
    description:
      "Indicates what percentage of the material used to make the product or its packaging has been sourced from recycled content. This signifies a commitment to environmental sustainability by reducing the demand for new raw materials, lowering energy consumption in production, and minimizing waste sent to landfills.",
  },
  {
    name: "rec90",
    description:
      "Indicates what percentage of the material used to make the product or its packaging has been sourced from recycled content. This signifies a commitment to environmental sustainability by reducing the demand for new raw materials, lowering energy consumption in production, and minimizing waste sent to landfills.",
  },
  {
    name: "rec80",
    description:
      "Indicates what percentage of the material used to make the product or its packaging has been sourced from recycled content. This signifies a commitment to environmental sustainability by reducing the demand for new raw materials, lowering energy consumption in production, and minimizing waste sent to landfills.",
  },
  {
    name: "rec70",
    description:
      "Indicates what percentage of the material used to make the product or its packaging has been sourced from recycled content. This signifies a commitment to environmental sustainability by reducing the demand for new raw materials, lowering energy consumption in production, and minimizing waste sent to landfills.",
  },
  {
    name: "rec60",
    description:
      "Indicates what percentage of the material used to make the product or its packaging has been sourced from recycled content. This signifies a commitment to environmental sustainability by reducing the demand for new raw materials, lowering energy consumption in production, and minimizing waste sent to landfills.",
  },
  {
    name: "rec50",
    description:
      "Indicates what percentage of the material used to make the product or its packaging has been sourced from recycled content. This signifies a commitment to environmental sustainability by reducing the demand for new raw materials, lowering energy consumption in production, and minimizing waste sent to landfills.",
  },
  {
    name: "mw1",
    description:
      "A manufacturer's warranty is a guarantee provided by the maker of a product, ensuring repair or replacement within the specified period if the product fails due to manufacturing defects. A longer warranty period promotes sustainability by encouraging the production of more durable goods, reducing waste and the need for frequent replacements.",
  },
  {
    name: "mw2",
    description:
      "A manufacturer's warranty is a guarantee provided by the maker of a product, ensuring repair or replacement within the specified period if the product fails due to manufacturing defects. A longer warranty period promotes sustainability by encouraging the production of more durable goods, reducing waste and the need for frequent replacements.",
  },
  {
    name: "mw3",
    description:
      "A manufacturer's warranty is a guarantee provided by the maker of a product, ensuring repair or replacement within the specified period if the product fails due to manufacturing defects. A longer warranty period promotes sustainability by encouraging the production of more durable goods, reducing waste and the need for frequent replacements.",
  },
  {
    name: "mw4",
    description:
      "A manufacturer's warranty is a guarantee provided by the maker of a product, ensuring repair or replacement within the specified period if the product fails due to manufacturing defects. A longer warranty period promotes sustainability by encouraging the production of more durable goods, reducing waste and the need for frequent replacements.",
  },
  {
    name: "mw5",
    description:
      "A manufacturer's warranty is a guarantee provided by the maker of a product, ensuring repair or replacement within the specified period if the product fails due to manufacturing defects. A longer warranty period promotes sustainability by encouraging the production of more durable goods, reducing waste and the need for frequent replacements.",
  },
  {
    name: "enga",
    description:
      "The energy label on an appliance shows how energy efficient it is. ‘A’ rated appliances are the most energy efficient and cost the least to run. G rated products are the least energy efficient and cost the most to run.",
  },
  {
    name: "engb",
    description:
      "The energy label on an appliance shows how energy efficient it is. ‘A’ rated appliances are the most energy efficient and cost the least to run. G rated products are the least energy efficient and cost the most to run.",
  },
  {
    name: "engc",
    description:
      "The energy label on an appliance shows how energy efficient it is. ‘A’ rated appliances are the most energy efficient and cost the least to run. G rated products are the least energy efficient and cost the most to run.",
  },
  {
    name: "engd",
    description:
      "The energy label on an appliance shows how energy efficient it is. ‘A’ rated appliances are the most energy efficient and cost the least to run. G rated products are the least energy efficient and cost the most to run.",
  },
  {
    name: "enge",
    description:
      "The energy label on an appliance shows how energy efficient it is. ‘A’ rated appliances are the most energy efficient and cost the least to run. G rated products are the least energy efficient and cost the most to run.",
  },
  {
    name: "engf",
    description:
      "The energy label on an appliance shows how energy efficient it is. ‘A’ rated appliances are the most energy efficient and cost the least to run. G rated products are the least energy efficient and cost the most to run.",
  },
  {
    name: "engg",
    description:
      "The energy label on an appliance shows how energy efficient it is. ‘A’ rated appliances are the most energy efficient and cost the least to run. G rated products are the least energy efficient and cost the most to run.",
  },
  {
    name: "ss",
    description:
      "This product's wood is certified by a third party. Information about the third party can be found on the product packaging.",
  },
  {
    name: "mib",
    description:
      "Any product carrying the Made In Britian mark has been made in Great Britain or Northern Ireland, and conforms to strict employment, health & safety, quality standards and ethical business practices.",
  },
  {
    name: "wide",
    description:
      "This label is applied to packaging or products that is collected by 75% or more of local authorities across the UK, for example plastic bottles.",
  },
  {
    name: "epd",
    description:
      "An Environmental Product Declaration is available for this material. This is a verified lifecycle assessment of the environmental impact of the material from cradle to grave.",
  },
  {
    name: "pefc",
    description:
      "The PEFC logo shows timber products that have full 'chain of custody' from forest to popint of purchase and every point in between through the Programme for the endorsement of Forests Certification accreditation scheme. ",
  },
  {
    name: "fsc",
    description:
      "The FSC logo shows timber products that have full 'chain of custody' from forest to popint of purchase and every point in between through the Forest Stewardshup Council's accreditation scheme. ",
  },
];

// eslint-disable-next-line no-unused-vars
export const availableBadges = Object.entries(SUSTAINABLE_DESCRIPTIONS).map(([_, value]) => {
  const { name } = value;
  return `icon-${name}`;
});
